import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faAirConditioner,
	faArrowRightLong,
	faBath,
	faBed,
	faBicycle,
	faBuildingColumns,
	faCalendarDays,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronUp,
	faCocktail,
	faCoffee,
	faCouch,
	faCroissant,
	faEnvelope,
	faGift,
	faGlobe,
	faGolfFlagHole,
	faHeart,
	faHotTubPerson,
	faLampDesk,
	faLink,
	faMasksTheater,
	faMicrophone,
	faMusic,
	faPersonHiking,
	faPersonSkiing,
	faPhone,
	faPumpSoap,
	faShip,
	faShower,
	faSpa,
	faStore,
	faTimes,
	faTree,
	faTv,
	faUtensils,
	faVault,
	faWater,
	faWifi,
	faWineBottle,
	faWineGlass,
} from '@fortawesome/pro-light-svg-icons';
import { faCheck, faCircleCheck, faBars, faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTwitter,
	faWhatsapp,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faStar, faQuoteLeft, faStarHalf } from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faAirConditioner,
	faArrowRightLong,
	faBars,
	faBath,
	faBed,
	faBicycle,
	faBuildingColumns,
	faCalendarDays,
	faCheck,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronUp,
	faCircleCheck,
	faCocktail,
	faCoffee,
	faCouch,
	faCroissant,
	faEnvelope,
	faFacebook,
	faGift,
	faGlobe,
	faGolfFlagHole,
	faHeart,
	faHotTubPerson,
	faInstagram,
	faLampDesk,
	faLink,
	faLinkedin,
	faLocationDot,
	faMasksTheater,
	faMicrophone,
	faMusic,
	faPersonHiking,
	faPersonSkiing,
	faPhone,
	faPinterest,
	faPumpSoap,
	faQuoteLeft,
	faShip,
	faShower,
	faSoundcloud,
	faSpa,
	faSpotify,
	faStar,
	faStarHalf,
	faStore,
	faTimes,
	faTree,
	faTv,
	faTwitter,
	faUtensils,
	faVault,
	faWater,
	faWhatsapp,
	faWifi,
	faWineBottle,
	faWineGlass,
	faYoutube,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
